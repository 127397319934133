import { getAllExpertProfiles, getLanguagesList } from "@/api";
import ExpertCard from "@/pages/SearchExpert/ExpertCard.vue";
import { googleGeoParse } from "@/helpers/workingWithObjects";
export default {
  components: { ExpertCard },
  data: () => ({
    q: null,
    allExpertProfiles: [],
    languages: null,
    languagesItems: [],
    specialisations: null,
    specialisationList: [],
    city: null,
    sorts: null,
    sortsList: [],
    page: 1,
    pagesLength: 2
  }),
  mounted() {
    this.sortsList = [
      { value: "name", text: this.$t("Name") },
      { value: "date", text: this.$t("Date") },
      { value: "rating", text: this.$t("Rating") },
      { value: "ID", text: this.$t("ID") }
    ];
    this.specialisationList = [
      { value: "mechanic", text: this.$t("mechanic") },
      { value: "electrician", text: this.$t("electrician") },
      { value: "machine_control", text: this.$t("machine_control") },
      { value: "electronic", text: this.$t("electronic") },
      { value: "programmer", text: this.$t("programmer") },
      { value: "electromechanic", text: this.$t("electromechanic") }
    ];
    this.getExperts();
    this.languagesList();
  },
  methods: {
    /**
     * Get available languages
     */
    languagesList() {
      getLanguagesList()
        .then(r => {
          this.languagesItems = r.data
            ? r.data.languages.map(l => {
                return {
                  text: l[1],
                  value: l[0]
                };
              })
            : [];
        })
        .catch(err => {
          console.error(err);
        });
    },
    searchByName() {
      if (this.q && this.q.length > 2) {
        this.getExperts();
      }
    },
    clearSearch(type = "") {
      if (type === "name") {
        this.q = null;
      }
      if (type === "specialisation") {
        this.specialisations = null;
      }
      if (type === "language") {
        this.languages = null;
      }
      this.getExperts();
    },
    getExperts() {
      const params = { page: this.page };
      if (this.q && this.q.length > 2) {
        params["name"] = this.q;
      }

      if (this.specialisations) {
        params["specialization"] = this.specialisations;
      }

      if (this.languages) {
        params["language"] = this.languages;
      }

      getAllExpertProfiles(params)
        .then(r => {
          this.allExpertProfiles = r.data.experts.map((i, k) => {
            return Object.assign({}, i, { id: k });
          });
          this.pagesLength = Math.ceil(+r.data.pagination.total / +r.data.pagination.per_page);
        })
        .catch(err => {
          console.error(err);
        });
    },

    /**
     * Update address information based on google highlights
     * @param e
     */
    getGeoData(e) {
      const parsedData = googleGeoParse(e);
      this.city = parsedData.city;
    },

    /**
     * Clear address
     */
    clearGeo() {
      this.city = null;
    }
  },
  watch: {
    page: "getExperts"
  }
};
