"<template>
  <v-card>
    <v-card-title>
      <v-list-item class="px-0">
        <v-list-item-avatar width="88" height="88" class="expert-avatar" color="secondary">
          <v-icon x-large v-if="!data.avatar_url" color="white" class="expert-icon">
            mdi-account-outline</v-icon
          >
          <v-img :src="data.avatar_url" srcset="" v-if="data.avatar_url" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="d-flex justify-space-between">
            <span class="expert-name">{{ data.name }}</span>
            <span v-if="data.rate"> ${{ data.rate }}/h</span>
          </v-list-item-title>
          <v-list-item-subtitle
            ><span class="expert-label text--secondary mr-2">{{ $t("Location") }}:</span>
            <span v-if="data.location"
              >{{ data.location.state }}/{{ data.location.city }}</span
            ></v-list-item-subtitle
          >
          <v-list-item-subtitle
            ><span class="expert-label text--secondary mr-2">{{ $t("Working area") }}:</span>
            <span v-if="data.working_area">{{ data.working_area }}</span></v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </v-card-title>
    <v-card-text class="expert-text">
      <v-chip small class="ma-2" v-for="spec in data.specializations" :key="spec.id">
        {{ spec.specialism | startCase }}
      </v-chip>
    </v-card-text>
    <v-card-actions class="pa-0" bottom>
      <v-btn
        class="expert-btn"
        color="primary"
        bottom
        block
        small
        :to="{
          name: 'ShowUserProfile',
          params: { id: data.user_id },
          query: { profile_view: 'expert-profile' }
        }"
      >
        {{ $t("View Profile") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash";
export default {
  name: "expert-card",
  props: ["expert"],
  data: () => ({}),
  computed: {
    data() {
      const data = this.expert[_.keys(this.expert)[0]];
      data.id = _.keys(this.expert)[0];
      return data;
    }
  }
};
</script>

<style lang="scss">
.expert-avatar {
  border-radius: 8px;
  color: #fff;
  .expert-icon {
    font-size: 30px;
  }
}

.expert-name {
  font-size: 18px;
}
.expert-label {
  font-size: 14px;
}
.expert-text {
  height: 100px;
  overflow: auto;
}
.expert-value {
}
.expert-btn {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
</style>
